import i18next from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getLanguageData } from "./languageData";

// ["en", "ru"].forEach((lang) => fetchTranslations(lang));

const languageData = getLanguageData();

i18next
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "ru",
    lng: "ru",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    saveMissingTo: "all",
    ...languageData,
  });

export default i18next;
