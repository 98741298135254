import SearchIcon from "@mui/icons-material/Search";
import { Alert, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useCustomerQuery } from "hooks/useCustomerQuery";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PageHeading } from "../UI/Typography/SharedTypography";
import { OverviewGridContainerStyled } from "./Shared";

const OverviewGrid = () => {
  const { t } = useTranslation();

  const currentCustomer = useSelector(
    (state) => state.contentTable.currentCustomer
  );

  const {
    clear,
    sendCustomerNumber,
    isFetching,
    handleCustomerNumberChange,
    customerNumberName,
    data,
    handleOnBlur,
  } = useCustomerQuery();

  return (
    <>
      <PageHeading sx={{ marginBottom: 4 }}>{t("overview.title")}</PageHeading>
      <OverviewGridContainerStyled>
        <Grid item md={6} xs={12} sx={{ marginTop: 2 }}>
          <TextField
            variant="filled"
            onFocus={clear}
            onBlur={handleOnBlur}
            disabled={isFetching}
            label={t("filterBar.customerLabel")}
            value={customerNumberName}
            onChange={handleCustomerNumberChange}
            onKeyDown={({ key }) => key === "Enter" && sendCustomerNumber()}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: "white",
                transform: "none",
                fontSize: "12px",
                "&.Mui-focused": {
                  color: "white",
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={sendCustomerNumber}>
                  <SearchIcon sx={{ color: "white" }} />
                </IconButton>
              ),
              sx: {
                color: "white",
                "&.Mui-focused": { background: "none" },
              },
            }}
            sx={{
              maxWidth: { md: "70%", xs: "100%" },
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
                border: "none",
              },
              "& .MuiInputBase-root:hover": {
                background: "none",
              },
              "& .MuiFilledInput-root": {
                borderBottom: "2px solid white",
              },
              "& .MuiFilledInput-input": {
                paddingLeft: "0px",
              },
              "& .MuiFilledInput-root:after": {
                border: "none",
              },
            }}
            fullWidth
          />
          {data?.length === 0 && (
            <Alert
              icon={false}
              sx={{
                mt: 3,
                backgroundColor: (theme) => theme.palette.text.primary,
                color: "white",
              }}
            >
              {t("filterBar.errorCustomerNotFound", {
                CUSTOMER_NUMBER: customerNumberName,
              })}
            </Alert>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="body"
            sx={{
              display: "block",
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            {t("overview.accountNumber")} {currentCustomer.customerNumber}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {currentCustomer.customerName}
          </Typography>
        </Grid>
      </OverviewGridContainerStyled>
    </>
  );
};

export default OverviewGrid;
