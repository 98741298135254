import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { localizeDate } from "helpers/helpers";
import { useActions } from "hooks/useActions";
import DeliveryOICountdown from "./DeliveryOICountdown";

const DeliveryOITableRow = ({ order, index, array }) => {
  const {
    removeTableRow,
    setIsTabsFormOpen,
    setSummary,
    setTabsForm,
    setCurrentMaterial,
  } = useActions();
  const tableRows = useSelector((state) => state.deliveryOI.tableRows);
  const isCutOffTime = useSelector((state) => state.deliveryOI.isCutOffTime);

  const date =
    order?.deliveryDate ||
    order?.businessDays[
      isCutOffTime ? "daysAfterCutOffTime" : "daysBeforeCutOffTime"
    ][0];

  const currentTableRowIndex = useSelector(
    (state) => state.deliveryOI.currentTableRowIndex
  );

  const [isTimeOut, setIsTimeOut] = useState(false);

  const backgroundColor = () => {
    if (isTimeOut) return { color: "#FF6666", hover: "#FF3333" };
    if (currentTableRowIndex === order.orderId)
      return { color: "#EBF3FC", hover: "#D4EEFF" };

    return { color: "white", hover: "#D4EEFF" };
  };

  const handleRemoveTableRow = (e, id) => {
    e.stopPropagation();
    removeTableRow(id);
    setSummary();
  };

  const handleRowClick = (order) => {
    const clickedRow = tableRows.find(
      (tableRow) => tableRow.orderId === order.orderId
    );
    setTabsForm({ ...clickedRow, isTimeOut });
    setIsTabsFormOpen(true);
    setCurrentMaterial(order.materials);
  };

  return (
    <TableRow
      hover
      onClick={() => handleRowClick(order)}
      sx={{
        backgroundColor: `${backgroundColor().color}`,
        "&.MuiTableRow-hover:hover": {
          backgroundColor: backgroundColor().hover,
        },
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell>
        <Box>
          <Typography>{index + 1}</Typography>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row">
        <Box>
          <Typography>{localizeDate(date)}</Typography>
          <Typography>{`${order.defaultDeliveryWindow.defaultEarliestLoadTime} - ${order.defaultDeliveryWindow.defaultLatestLoadTime}`}</Typography>
        </Box>
        <DeliveryOICountdown date={date} setIsTimeOut={setIsTimeOut} />
      </TableCell>
      <TableCell>
        <Box>
          <Typography>
            {isTimeOut
              ? "TimeOut"
              : `${
                  order.materials[0]?.materialDescription ||
                  order.materials?.materialDescription
                }`}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{order.truckCapacity.capacity}</TableCell>
      <TableCell>
        {order.haulerInfo && (
          <>
            <Typography
              sx={{ fontSize: "12px" }}
            >{`${order.haulerInfo.driverName} ${order.haulerInfo.driverPhoneNumber}`}</Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {`${order.haulerInfo.truckLicensePlate}`}
              {order.haulerInfo.trailerLicensePlate &&
                `|${order.haulerInfo.trailerLicensePlate}`}
            </Typography>
          </>
        )}
        {order.driverInstructions && order.driverInstructions}
      </TableCell>
      <TableCell>{order.customerReference}</TableCell>
      <TableCell>
        <IconButton
          onClick={(e) => handleRemoveTableRow(e, order.orderId)}
          sx={{ visibility: `${array.length === 1 && "hidden"}` }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default DeliveryOITableRow;
