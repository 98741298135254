import DeliveryItem from "./DeliveryItem";

const InvoiceDeliveryCards = ({ isViewAll, deliveryCards }) => {
  return isViewAll ? (
    deliveryCards.map((delivery, index) => (
      <DeliveryItem key={index} delivery={delivery} isShown={isViewAll} />
    ))
  ) : (
    <DeliveryItem delivery={deliveryCards[0]} />
  );
};

export default InvoiceDeliveryCards;
