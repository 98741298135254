import { Modal, Paper, useMediaQuery } from "@mui/material";
import { ease, slideCard } from "App";
import InvoiceCostSummary from "Components/Invoices/InvoiceCostSummary";
import StyledExportButton from "Components/UI/StyledExportButton/StyledExportButton";
import FullscreenInvoiceDeliveriesItems from "./FullscreenInvoiceDeliveriesItems";

const FSInvoiceSide = ({
  isDeliveryItemsOpen,
  setIsDeliveryItemsOpen,
  currentInvoiceData,
}) => {
  const isBelowLgr = useMediaQuery((theme) => theme.breakpoints.down("lgr"));
  const isBelowMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const renderContent = () => {
    if (isBelowLgr && isBelowMd)
      return (
        <>
          <Modal
            open={isDeliveryItemsOpen}
            hideBackdrop
            sx={{
              top: "63px",
              zIndex: 3,
              animation: `${ease} .3s ease`,
            }}
          >
            <Paper
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                  display: !isDeliveryItemsOpen && "none",
                  padding: 4,
                  position: "fixed",
                  top: "63px",
                  overflowY: "auto",
                  right: "0",
                  left: "0",
                  bottom: "0",
                  zIndex: 1,
                  scrollbarGutter: "stable",
                },
              })}
            >
              <FullscreenInvoiceDeliveriesItems
                setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
                currentInvoiceData={currentInvoiceData}
              />
            </Paper>
          </Modal>
          <StyledExportButton sx={{ marginBottom: 2 }} />
          <InvoiceCostSummary currentInvoiceData={currentInvoiceData} />
        </>
      );
    else if (isBelowLgr)
      return (
        <>
          <Paper
            sx={(theme) => ({
              [theme.breakpoints.down("lgr")]: {
                display: !isDeliveryItemsOpen && "none",
                padding: 4,
                position: "absolute",
                top: "0",
                right: "0",
                width: "40%",
                maxHeight: "calc(-180px + 100vh)",
                animation: `${slideCard} .2s`,
                height: "100%",
                overflowY: "auto",
                zIndex: 1,
                scrollbarGutter: "stable",
              },
            })}
          >
            <FullscreenInvoiceDeliveriesItems
              setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
              currentInvoiceData={currentInvoiceData}
            />
          </Paper>

          <StyledExportButton sx={{ marginBottom: 2 }} />
          <InvoiceCostSummary currentInvoiceData={currentInvoiceData} />
        </>
      );
    else
      return (
        <>
          {isDeliveryItemsOpen ? (
            <FullscreenInvoiceDeliveriesItems
              setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
              currentInvoiceData={currentInvoiceData}
            />
          ) : (
            <>
              <StyledExportButton sx={{ marginBottom: 2 }} />
              <InvoiceCostSummary currentInvoiceData={currentInvoiceData} />
            </>
          )}
        </>
      );
  };

  return (
    <Paper
      sx={{
        padding: 4,
        height: "100%",
        overflowY: "auto",
        scrollbarGutter: "stable",
      }}
    >
      {renderContent()}
    </Paper>
  );
};

export default FSInvoiceSide;
