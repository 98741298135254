import { createContext } from "react";

const PageTypeContext = createContext("Test");

export const PageTypeProvider = ({ children, PageType }) => {
  return (
    <PageTypeContext.Provider value={PageType}>
      {children}
    </PageTypeContext.Provider>
  );
};

export default PageTypeContext;
