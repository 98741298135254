import { getDefaultCustomerFromLS } from "helpers/localstoragehelper";
import { apiSlice } from "./apiSlice";
const INVOICES = "invoices/";
const DELIVERIES = "deliveries/";

export const orderListApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: (arg) => ({
        url: INVOICES,
        params: { ...arg },
      }),
      providesTags: ["Invoices"],
    }),
    getInvoiceDeliveries: builder.query({
      query: (invoiceId) => ({
        url: `${DELIVERIES}?customerId=${
          getDefaultCustomerFromLS().customerId
        }&invoiceId=${invoiceId}`,
      }),
    }),
    filterByInvoice: builder.query({
      query: ({ customerId, invoiceNumber }) =>
        `${INVOICES}?customerId=${customerId}&invoiceNumber=${invoiceNumber}`,
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData(
              "getInvoices",
              { ...getState().contentTable.invoiceQuery },
              () => data
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const { useGetInvoicesQuery } = orderListApiSlice;
export const { useGetInvoiceDeliveriesQuery } = orderListApiSlice;

export const { useLazyFilterByInvoiceQuery } = orderListApiSlice;
