import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, ButtonBase, Grid, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentStatusPopover from "./PaymentStatusPopover";
import PaymentStatusText from "./PaymentStatusText";

const PaymentStatusFilter = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverWidth(e.currentTarget.offsetWidth);
  };
  const statusArray = [
    "filterMenu.allStatus",
    "invoice.paymentStatusFilter.open",
    "invoice.paymentStatusFilter.closed",
    "invoice.paymentStatusFilter.partiallyPaid",
  ];
  // const statusArray = ["All Status", "Open", "Fully Paid", "Partially Paid"];

  const [choices, setChoices] = useState(statusArray);

  const popoverContentProps = {
    popoverWidth,
    setChoices,
    choices,
    statusArray,
    anchorEl,
    setAnchorEl,
  };

  return (
    <Grid item>
      <ButtonBase
        onClick={handleClick}
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        <Paper
          square={false}
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.inputBackgroundColor.main,
            border: (theme) =>
              `1px solid ${theme.palette.inputBorderColor.main}`,
            padding: "5px",
            paddingBottom: "8px",
            paddingLeft: "12px",
            "&:hover": {
              background: "#E7E7E7",
            },
          }}
        >
          <Box maxWidth="90%" minWidth="222px">
            <Stack textAlign="left">
              <Typography variant="caption" color="text.secondary">
                {t("invoice.paymentStatus")}
              </Typography>
              <PaymentStatusText choices={choices} />
            </Stack>
          </Box>
          <Box>
            <ArrowDropDownIcon />
          </Box>
        </Paper>
      </ButtonBase>
      <PaymentStatusPopover {...popoverContentProps} />
    </Grid>
  );
};

export default PaymentStatusFilter;
