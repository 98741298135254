import { Typography } from "@mui/material";
import {
  lastMonthEnd,
  lastMonthStart,
  today,
  weekFromToday,
} from "constants/dates";
import { formattedDateRange } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import StyledCalendarToggleButton from "./StyledCalendarToggleButton";

const SelectionOrders = ({ setLastMonth, setOneWeek, setToday }) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledCalendarToggleButton
        value="week"
        aria-label="one week"
        onClick={setOneWeek}
      >
        <Typography>{t("order.datePicker.oneWeek")}</Typography>
        <Typography>{`${formattedDateRange(today, weekFromToday)}`}</Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="today"
        aria-label="today"
        onClick={setToday}
      >
        <Typography>{t("order.datePicker.today")}</Typography>
        <Typography>{formattedDateRange(today)}</Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="last month"
        aria-label="last month"
        onClick={setLastMonth}
      >
        <Typography>{t("order.datePicker.lastMonth")}</Typography>
        <Typography>
          {formattedDateRange(lastMonthStart, lastMonthEnd)}
        </Typography>
      </StyledCalendarToggleButton>
    </>
  );
};

export default SelectionOrders;
