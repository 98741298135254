import { CardContent, Grid, Paper } from "@mui/material";
import FullScreenGridItem from "Components/FullscreenOrder/FullScreenGridItem";
import PdfLink from "Components/UI/PdfLink/PdfLink";
import { useTranslation } from "react-i18next";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";

const DeliveryItem = ({ delivery, isShown = false }) => {
  const { t } = useTranslation();
  console.log(delivery);

  const formatShippingTypeText = (shippingType) => {
    if (shippingType === "collect")
      return t("invoiceDetail.lineItems.expand.collect");
    if (shippingType === "deliver")
      return t("invoiceDetail.lineItems.expand.deliver");
  };

  return (
    <Paper
      sx={{
        marginY: 1,
        border: (theme) => `1px solid ${theme.palette.inputBorderColor.main}`,
        background: (theme) => theme.palette.inputBackgroundColor.main,
        width: "100%",
        maskImage: isShown
          ? "none"
          : "linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        borderRadius: "8px",
        borderImage: "initial",
        overflow: "hidden",
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <FullScreenGridItem>
            <OrderCardHeading>
              {t("invoice.expand.deliveryNumber")}
            </OrderCardHeading>
            <PdfLink>{delivery.deliveryNumber}</PdfLink>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>
              {t("invoice.expand.orderPoNumber")}
            </OrderCardHeading>
            <OrderCardText>{delivery.orderNumber}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem sm={12}>
            <OrderCardHeading> {t("invoice.expand.material")}</OrderCardHeading>
            <OrderCardText>{`${delivery.materialDescription}`}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem sm={12}>
            <OrderCardHeading>{t("invoice.expand.quantity")}</OrderCardHeading>
            <OrderCardText>{`${delivery.loadQuantity} ${delivery.loadQuantityUom}`}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>
              {t("order.orderDetails.vehicleLicencePlate")}
            </OrderCardHeading>
            <OrderCardText>{delivery.truckName}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>{t("invoice.expand.transport")}</OrderCardHeading>
            <OrderCardText>
              {formatShippingTypeText(delivery.shippingType)}
            </OrderCardText>
          </FullScreenGridItem>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default DeliveryItem;
