import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import StatusIcon from "../UI/StatusIcon/StatusIcon";

const FullScreenDeliveriesStatus = ({ status }) => {
  const { t } = useTranslation();
  if (status === "invoiced") {
    return <Button> {t("order.expand.label.invoiced")}</Button>;
  }
  if (status === "intransit") {
    return (
      <>
        <Box paddingTop="2px">
          <StatusIcon small status="intransit" />
        </Box>
        {t("order.expand.label.intransit")}
      </>
    );
  }
  return null;
};

export default FullScreenDeliveriesStatus;
