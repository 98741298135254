import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, TextField } from "@mui/material";
import { useCustomerQuery } from "hooks/useCustomerQuery";
import { useTranslation } from "react-i18next";

const CustomerNumberFilter = () => {
  const { t } = useTranslation();

  const {
    clear,
    sendCustomerNumber,
    isFetching,
    handleCustomerNumberChange,
    customerNumberName,
    handleOnBlur,
  } = useCustomerQuery();

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexShrink: 0,
        backgroundColor: theme.palette.inputBackgroundColor.main,
        border: `1px solid ${theme.palette.inputBorderColor.main}`,
        borderRadius: "4px",
        "&:hover": {
          background: "#e7e7e7",
        },
      })}
    >
      <TextField
        variant="filled"
        onFocus={clear}
        onBlur={handleOnBlur}
        disabled={isFetching}
        label={t("filterBar.customerLabel")}
        value={customerNumberName}
        onChange={handleCustomerNumberChange}
        onKeyDown={({ key }) => key === "Enter" && sendCustomerNumber()}
        InputProps={{
          disableUnderline: true,
          sx: {
            borderRadius: 0,
          },
        }}
        sx={{
          "& .MuiInputBase-root": {
            background: "none",
            border: "none",
          },
        }}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          borderLeft: `1px solid ${theme.palette.inputBorderColor.main}`,
        })}
      >
        <IconButton
          onClick={sendCustomerNumber}
          sx={{
            "&:hover": {
              background: "none",
            },
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomerNumberFilter;
