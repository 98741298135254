import PolicyIcon from "@mui/icons-material/Policy";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NavLinkStyled from "../UI/NavLinkStyled/NavLinkStyled";

const LegalMenu = ({ closeMenu }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    closeMenu();
  };

  return (
    <>
      <MenuItem id="appbar-menu-legal" onClick={handleClick}>
        <ListItemIcon>
          <PolicyIcon fontSize="small" />
        </ListItemIcon>
        <Typography textAlign="center">
          {t("accountMenu.legal.title")}
        </Typography>
      </MenuItem>
      <Menu
        id="appbar-menu-legal"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <NavLinkStyled to="/tos">
          <MenuItem onClick={handleClose}>
            {t("accountMenu.legal.termsofuse")}
          </MenuItem>
        </NavLinkStyled>
        <NavLinkStyled to="/privacy">
          <MenuItem onClick={handleClose}>
            {t("accountMenu.legal.privacy")}
          </MenuItem>
        </NavLinkStyled>
        <NavLinkStyled to="/oss">
          <MenuItem onClick={handleClose}>
            {t("accountMenu.legal.openSourceSoftwareAttribution")}
          </MenuItem>
        </NavLinkStyled>
      </Menu>
    </>
  );
};

export default LegalMenu;
