import TranslateIcon from "@mui/icons-material/Translate";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageMenu = ({ closeMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    closeMenu();
  };

  const { i18n, t } = useTranslation();

  const handleLanguage = (e) => {
    const text = e.target.innerText;
    if (!text) return;
    if (text === t("language.en")) i18n.changeLanguage("en");
    if (text === t("language.ru")) i18n.changeLanguage("ru");
    handleClose();
  };

  return (
    <>
      <MenuItem id="appbar-menu-legal" onClick={handleClick}>
        <ListItemIcon>
          <TranslateIcon fontSize="small" />
        </ListItemIcon>
        <Typography textAlign="center">{t("accountMenu.language")}</Typography>
      </MenuItem>
      <Menu
        id="appbar-menu-legal"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLanguage}>{t("language.en")}</MenuItem>
        <MenuItem onClick={handleLanguage}>{t("language.ru")}</MenuItem>
      </Menu>
    </>
  );
};

export default LanguageMenu;
