export const OrderTableStyles = {
  bgcolor: "white",
  "& .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "rgb(245, 246, 247)",
    textAlign: "center",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "10px",
    textTransform: "uppercase",
    fontWeight: 700,
  },
  "& .MuiDataGrid-cell": {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    color: "text.primary",
  },
  "& .MuiDataGrid-cellCheckbox": {
    paddingTop: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-row:hover": {
    backgroundColor: "rgb(245, 246, 247)",
  },
};
