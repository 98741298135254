import { Box, Button, Divider, Skeleton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetInvoiceDeliveriesQuery } from "reduxStore/api/invoicesListApiSlice";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";
import InvoiceDeliveryCards from "./InvoiceDeliveryCards";

const InvoiceDeliveryItems = ({ currentInvoiceData }) => {
  const { t } = useTranslation();
  const [isViewAll, setIsViewAll] = useState(false);

  const handleViewAll = () => {
    setIsViewAll((prev) => !prev);
  };

  const { data, isFetching } = useGetInvoiceDeliveriesQuery(
    currentInvoiceData.invoiceId,
    { skip: !currentInvoiceData }
  );

  if (!currentInvoiceData.lineItems.length) return null;

  return (
    <>
      <Divider sx={{ marginY: 1 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <OrderCardHeading variant="subtitle">
            {t("order.orderDetails.deliveryItems")}
          </OrderCardHeading>
          <OrderCardText>
            {data?.count} {t("order.orderDetails.deliveryItems")}
          </OrderCardText>
        </Box>
        <Button onClick={handleViewAll}>{t("overview.showAll")}</Button>
      </Box>
      <Box>
        {isFetching && <Skeleton sx={{ height: "250px", transform: "none" }} />}
        {!isFetching && (
          <InvoiceDeliveryCards
            isViewAll={isViewAll}
            deliveryCards={data.results}
          />
        )}
      </Box>
    </>
  );
};

export default InvoiceDeliveryItems;
