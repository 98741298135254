import { MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const NumberFilterSelect = ({ handleLabelChange }) => {
  const { t } = useTranslation();

  const [filterType, setFilterType] = useState("Invoice");
  const handleSelectChange = ({ target }) => {
    setFilterType(target.value);

    if (target.value === "Invoice")
      handleLabelChange("invoice.lookup.labelinvoiceNo");
    if (target.value === "Ticket")
      handleLabelChange("invoice.lookup.labelticketNo");
  };
  return (
    <Select
      id="number-type-filter"
      label="numberType"
      labelId="number-type-filter-label"
      value={filterType}
      onChange={handleSelectChange}
      variant="standard"
      disableUnderline
      sx={(theme) => ({
        borderLeft: `1px solid ${theme.palette.inputBorderColor.main}`,
        margin: "8px 0",
        paddingLeft: "15px",
        minWidth: "100px",
      })}
      MenuProps={{
        sx: {
          "& .MuiMenu-list": {
            background: "#001E2A",
            color: "white",
          },
        },
      }}
    >
      <MenuItem value="Invoice">{t("invoice.lookup.invoiceNo")}</MenuItem>
      <MenuItem value="Ticket">{t("invoice.lookup.ticketNo")}</MenuItem>
    </Select>
  );
};

export default NumberFilterSelect;
