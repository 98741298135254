import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { ButtonBase, Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { formatInvoiceStatusText, localizeDate } from "helpers/helpers";
import { useDetailsCard } from "hooks/useDetailsCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  OrderCardHeading,
  OrderCardSub,
  OrderCardText,
} from "../UI/Typography/OrderCard";

const InvoiceSummary = ({ currentInvoiceData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToFullscreen = () =>
    navigate(`/invoices/${currentInvoiceData.customerInvoiceNumber}`, {
      state: currentInvoiceData,
    });

  const { closeDetailsCard } = useDetailsCard();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <OrderCardText sx={{ textTransform: "capitalize" }}>
          {t("invoice.invoice")} {t("invoice.expand.summary")}
        </OrderCardText>
        <Box>
          <ButtonBase>
            <OpenInFullIcon
              onClick={navigateToFullscreen}
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
          <ButtonBase onClick={closeDetailsCard}>
            <CloseIcon
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
        </Box>
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "center", marginBottom: 1 }}
      >
        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
          <OrderCardHeading>
            {t("invoiceDetail.invoiceStatus")}
          </OrderCardHeading>
          <OrderCardText sx={{ fontWeight: 700, color: "#29AAFF" }}>
            {formatInvoiceStatusText(currentInvoiceData.status)}
          </OrderCardText>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>{t("invoiceDetail.creationDate")}</OrderCardHeading>
          <Box>
            <OrderCardSub>
              {localizeDate(currentInvoiceData.creationDate)}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.invoiceDate.invoice")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>
              {localizeDate(currentInvoiceData.invoiceDate)}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.invoiceNumber.invoice")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>
              {currentInvoiceData.customerInvoiceNumber}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">
            {t("invoiceDetail.soldTo")}
          </OrderCardHeading>
          <Box>
            <OrderCardText>
              {currentInvoiceData.payer.customerName}
            </OrderCardText>
            <OrderCardSub>{`${currentInvoiceData.payer.street}`}</OrderCardSub>
            <OrderCardSub>
              {`${currentInvoiceData.payer.city}, ${currentInvoiceData.payer.postalCode}`}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">
            {t("invoiceDetail.billTo")}
          </OrderCardHeading>
          <Box>
            <OrderCardText>
              {currentInvoiceData.billTo.customerName}
            </OrderCardText>
            <OrderCardSub>{`${currentInvoiceData.billTo.street}`}</OrderCardSub>
            <OrderCardSub>
              {`${currentInvoiceData.billTo.city}, ${currentInvoiceData.billTo.postalCode}`}
            </OrderCardSub>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceSummary;
